/* css for decidim_ludens */

.avatar{
  width:7vw;
}

.assistant_values{
  margin:auto;
}

.recap_assistant{
  padding: 10px 50px 10px 10px;
  margin:10px;
  border-right: solid 0.5px lightgray;
}

.written_values{
  margin:auto;
}

.letter_spaced{
  letter-spacing: 1.6px;
}

.no-bullet, .bulleted{
  list-style-type: none;
}

.no-bullet::before {
  content:"✓";
  padding-right: 3px;
}

.recommendation{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.recommendation-points{
  color:gray;
  font-size: small;
  margin-left: 1vw;
}

.bulleted::before{
  content:"•";
  padding-right: 12px;
}

.assistant_container{
  display:flex;
  align-items: flex-end;
  justify-content: space-between;
}

.assistant_recommendations{
  margin-left:3vw;
  margin-top:auto;
  margin-bottom:auto;
  font-family: "Source Sans Pro", Helvetica, Roboto, Arial, sans-serif;
}

.recap_assistant .progress{
  height: 5px;
  width: 9vw;
  background-color: lightgray;
}

.recap_assistant .progress_bar{
  position: absolute;
  background-color: green;
  width: 3vw;
  height: 6px;
  border-radius: 15px;
}

.levelsImage{
  width: 4vw;
}

.desc_levels {
  display: flex;
  flex-wrap: nowrap;
}
.desc_levels > * {
  flex-basis: 100%;
}

.desc_level{
  margin-bottom: 10px;
  margin-top: 10px;
  padding:10px;
}

.desc_levels > *
{
  border-right: solid 0.5px lightgray;
}

.desc_level_last{
  border-right: none !important;
}

.levelDescription{
  font-size: small;
}

.recommendation-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.recommendation-firstContainer{
  margin:10px;
  display:flex;
  align-items:start;
  justify-content:space-evenly;
}

.card-recommandation{
  min-height: 21vh;
}

.list-actions-uncompleted, .list-actions-completed{
  text-overflow: ellipsis;
}